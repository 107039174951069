

export class MapResult {

	constructor(element) {

		//console.log("MapResult:", element);

		this.element = element;

	}

	transformData = (data) => {

		//let newData;

		//let id, id_lat, id_long;

		// Transform data in results


		let lang = navigator.language || "de-DE";

		let formatter = new Intl.NumberFormat(lang, { maximumSignificantDigits: 6 })

		for (let row of data.results.bindings) {

			if (row[this.element.id]) {

				let uri = row[this.element.id].value;

				let lng = row[this.element.id + "_lng"].value;
				let lat = row[this.element.id + "_lat"].value;

				let localLng = formatter.format(lng);
				let localLat = formatter.format(lat);

				delete row[this.element.id];
				delete row[this.element.id + "_lat"];
				delete row[this.element.id + "_lng"];

				row[this.element.id] = { type: "uri", value: uri, label: localLat + ", " + localLng, lngLat: [lng, lat] };

			}

		}

		// Transform headers

		data.head.vars = data.head.vars.filter(hvar => hvar !== this.element.id + "_lat");
		data.head.vars = data.head.vars.filter(hvar => hvar !== this.element.id + "_lng");


		//let newCol;

		return data;

	}

}
