

export class DateResult {

	constructor(element) {

		//console.log("DateResult:", element);

		this.element = element;

		// Pending proper reimplementation

		this.replaceId = {
			"7c96eb02cc9750d69a916f29575b273c86ce9518d958d287c9731997c4a6aaea": "d4ea5e08a7645f2aea333d0ecfdea4a6f7efeccddc2665e58b01422fde1aa787",
			"8e2de3335023201ffa4d803767bdf12790c58f4f241554c544bde8bf7b8455a4": "57619f8a7cd2a6cda338074ef730b7346dde511d0422eb4a614e2bc4eec8dfae"
		}

	}

	transformData = (data) => {

		let fixedId = this.element.id;

		let lang = navigator.language || "de-DE";

		let formatter = new Intl.DateTimeFormat(lang);

		for (let row of data.results.bindings) {

			if (!row[fixedId]) {
				fixedId = this.replaceId[fixedId];
			}


			//console.log(row[this.element.id])

			if (row[fixedId]) {

				let raw_value = row[fixedId].value.split("-")

				let value;

				if (raw_value[0] == 0 && raw_value[1] == 0 && raw_value[2] == 0) {

					value = "-"

				} else {

					value = formatter.format(new Date(raw_value[0], raw_value[1] - 1, raw_value[2]));

				}

				row[fixedId] = { type: "date", value: value };

			}

		}

		return data;

	}

}
