import React from "react";

import Map, { Layer, Source, FillLayer, Popup, AttributionControl, NavigationControl, useControl } from 'react-map-gl';

import { MapDisclaimer, MapDisclaimerModal } from "./MapDisclaimer.js";



import {
	marker, routeStyle
} from '../../../styles/Styles.js';

import { Message, Container, FlexboxGrid, Panel, List, Stack, Tag, Badge } from "rsuite";


import {
	DESCRIBE_URI,
} from "../../../config/config.js";

const TransformURI = (uri) => {

	if (uri.startsWith("http://data.europa.eu/949/")) {

		return "/describe#" + encodeURIComponent(uri);

	} else {

		return uri;

	}

}

export class SearchResultMap extends React.Component {


	constructor(props) {

		super(props);

		({ data: this.data, formDefinition: this.formDefinition, query: this.query, skosValues: this.skosValues } = props);

		let { results, head } = this.data;

		this.token = "pk.eyJ1Ijoic3VzaGlsZ2hhbWJpciIsImEiOiJjazUyZmNvcWExM2ZrM2VwN2I5amVkYnF5In0.76xcCe3feYPHsDo8eXAguw";

		this.locVars = [
			"7f39b757e191637f73203093c178a5e9efa2d0d977979ba3cf9aa09e8a764b94",
			"60ec1ba6cd5c276d4069d1ba00c4127a7339c07ed038e05a8398b2ace0e05d1e",
			"68de027727d5508346f687b553d1b69beb11765d01b030b5f685d43a3b3e744c"
		];



		this.state = {

			center: [4.360854, 50.859658],
			bounds: this.maxBounds,
			loadMap: false,
			mapType: null,
			featureShown: (<><i>Hover a map feature with the mouse cursor to show related properties.</i></>),
			warningProps: false,
			warningResults: false,
			hoverLayer: false,
			cursor: "default",
			mapDisclaimer: false

		}

		this.state.head = head;
		this.state.results = results;


		for (let form of this.formDefinition) {

			if (form.object.value === this.query.rootObject) {

				this.elements = form.elements;

			}

		}


		this.geojson = {
			"type": "FeatureCollection",
			"crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:OGC:1.3:CRS84" } },
			"features": [
			]
		}

		this.geojsonHover = {
			"type": "FeatureCollection",
			"crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:OGC:1.3:CRS84" } },
			"features": [
			]
		}


		this.linesLayer = {
			'id': 'data',
			'type': 'line',
			'paint': {
				'line-color': "rgba(0,0,0,0.8)",
				'line-width': 3
			}

		};

		this.pointsLayer = {
			'id': 'data',
			type: 'circle',
			paint: {
				'circle-radius': 5,
				'circle-color': '#007cbf'
			}

		};

		this.linesLayerHover = {
			'id': 'data2',
			'type': 'line',
			'paint': {
				'line-color': "rgba(50,50,250,0.7)",
				'line-width': 5,
				"line-dasharray": [3, 1]
			}

		};

		this.pointsLayerHover = {
			'id': 'data3',
			type: 'circle',
			paint: {
				'circle-radius': 8,
				'circle-color': '#bf7cbf'
			}

		};

	}

	componentDidMount() {

		this.checkValidData();

		this.calculateDataBounds();

	}

	checkValidData = () => {

		if (this.state.results.bindings.length > 0) {

			if (this.state.head.vars.includes(this.locVars[0])) {

				this.setState({ mapType: "points" });

				this.setState({ loadMap: true });

			}

			if ((this.state.head.vars.includes(this.locVars[1]) &&
				this.state.head.vars.includes(this.locVars[2]))) {

				this.setState({ mapType: "lines" });

				this.setState({ loadMap: true });

			}

		} else {

			this.setState({ warningResults: true });

		}

	}

	calculateDataBounds() {

		let maxLng = -30, minLng = 50, maxLat = 40, minLat = 75;

		try {

			if (this.state.head.vars.includes(this.locVars[0])) { // OP case

				for (let binding of this.state.results.bindings) {

					if (this.locVars[0] in binding) {

						let lngLat = binding[this.locVars[0]].lngLat;

						maxLng = Math.max(maxLng, lngLat[0])
						minLng = Math.min(minLng, lngLat[0])
						maxLat = Math.max(maxLat, lngLat[1])
						minLat = Math.min(minLat, lngLat[1])

					}

				}

				//console.log("Data bounds:",  maxLng, minLng, maxLat, minLat);

				this.setState({ bounds: [[minLng, minLat], [maxLng, maxLat]] });

			}


			if ((this.state.head.vars.includes(this.locVars[1]) &&
				this.state.head.vars.includes(this.locVars[2]))) { // SoL case

				for (let binding of this.state.results.bindings) {

					if (this.locVars[1] in binding && this.locVars[2] in binding) {

						let lngLat = binding[this.locVars[1]].lngLat;

						maxLng = Math.max(maxLng, lngLat[0])
						minLng = Math.min(minLng, lngLat[0])
						maxLat = Math.max(maxLat, lngLat[1])
						minLat = Math.min(minLat, lngLat[1])

						lngLat = binding[this.locVars[2]].lngLat;

						maxLng = Math.max(maxLng, lngLat[0])
						minLng = Math.min(minLng, lngLat[0])
						maxLat = Math.max(maxLat, lngLat[1])
						minLat = Math.min(minLat, lngLat[1])

					}

				}

				//console.log("Data bounds:",  maxLng, minLng, maxLat, minLat);

				this.setState({ bounds: [[minLng, minLat], [maxLng, maxLat]] });

			}

		} catch (e) {

			this.setState({ bounds: [[minLng, minLat], [maxLng, maxLat]] });

		}


	}

	replaceHeader = (head) => {

		let label = "<missing label>";

		if (this.elements[head] !== undefined) {

			label = this.elements[head].input.definition.label;

		}

		return label;

	}

	formatElement = ({ rowData, dataKey }) => {

		let type, value, label, dataType;

		if (rowData[dataKey] === undefined) { // Must be handled differently

			type = "none";

			value = "N/A";

		} else {

			({ type: type, value: value, label: label, datatype: dataType } = rowData[dataKey]);

		}

		//console.log(elements[dataKey].results);

		let text = value;
		let prefix;

		let cell_content = value;

		// URIs

		if (type === "uri") {

			text = this.skosValues[text] || label || text;

			if (dataKey === "e2e8d0ff44169432a9b6a49d415768b6aeb230e6e308eacd14c58203edec7c1e") {

				if ("2050c6d81d3a75cb5768f6ce572c07da8a1406f7d4ec1ba3bae520f30f94525e" in rowData) {

					({ value: text } = rowData["2050c6d81d3a75cb5768f6ce572c07da8a1406f7d4ec1ba3bae520f30f94525e"]);

				} else {

					text = text.replace("http://data.europa.eu/949/functionalInfrastructure/operationalPoints/", "op:");

				}

				//console.log(text, value, rowData["2050c6d81d3a75cb5768f6ce572c07da8a1406f7d4ec1ba3bae520f30f94525e"]);

			}

			if (dataKey === "fa632b76f18dab740f50a2c2ffe35c08fb9f336e39957c286528f3e6a596ecbc") {

				if ("442d071369075e84260295ab6eb90397fa0b2dcee1441658b5d82b867c317f78" in rowData &&
					"ae50abaa747adda97f1fb4980dcbd6bb1b631431cf76dd487b1981bedd05eab7" in rowData) {

					let value1, value2;

					({ value: value1 } = rowData["442d071369075e84260295ab6eb90397fa0b2dcee1441658b5d82b867c317f78"]);
					({ value: value2 } = rowData["ae50abaa747adda97f1fb4980dcbd6bb1b631431cf76dd487b1981bedd05eab7"]);

					text = value1 + "-" + value2;


				} else {

					text = text.replace("http://data.europa.eu/949/functionalInfrastructure/sectionsOfLine/", "sol:");

				}

				//console.log(text, value, rowData["2050c6d81d3a75cb5768f6ce572c07da8a1406f7d4ec1ba3bae520f30f94525e"]);

			}

			cell_content = (
				<Tag color="grey" style={{ border: "dotted 1px #777" }}><a href={TransformURI(value)} target="_blank">{text}</a></Tag>
			);

		}


		// Booleans (color)

		if (type === "typed-literal" && dataType === "http://www.w3.org/2001/XMLSchema#boolean") {

			if (value === "1") {

				cell_content = (<Tag color="green">True</Tag>);

			} else {

				cell_content = (<Tag color="red">False</Tag>);

			}

		}


		// Empty formatting

		if (value === "N/A") {

			cell_content = (<Tag color="yellow"><i>no data</i></Tag>);
		}

		return cell_content


	}

	updatePoints = () => {

		let points = [];

		this.setState({ showFeatures: false })

		for (let binding of this.state.results.bindings) {

			if (this.locVars[0] in binding) {

				try {

					let lngLat = binding[this.locVars[0]].lngLat;

					let geojson_point = {
						"type": "Feature",
						"geometry": {
							"type": "Point",
							"coordinates": lngLat
						},
						"properties": {
							geo: {
								"type": "Point",
								"coordinates": lngLat
							}, ...binding
						}
					}

					points.push(geojson_point);

				} catch (e) {

					//console.log("Unable to parse entity location");

				}

			}




		}

		this.geojson.features = points;

		this.setState({ showFeatures: true })

	}

	updateLines = () => {

		let lines = [];

		this.setState({ showFeatures: false })

		for (let binding of this.state.results.bindings) {

			try {

				let lngLat1 = binding[this.locVars[1]].lngLat;
				let lngLat2 = binding[this.locVars[2]].lngLat;

				let geojson_line = {
					"type": "Feature",
					"geometry": {
						"type": "LineString",
						"coordinates": [lngLat1, lngLat2]
					},
					"properties": {
						geo: {
							"type": "LineString",
							"coordinates": [lngLat1, lngLat2]
						}, ...binding
					}
				}


				lines.push(geojson_line);

			} catch (e) {

				//console.log("Unable to parse entity location");

			}



		}

		this.geojson.features = lines

		this.setState({ showFeatures: true })

	}

	renderGeoJSON = () => {

		if (this.state.mapType === "points") {

			this.updatePoints();

		}

		if (this.state.mapType === "lines") {

			this.updateLines();

		}

		//console.log(this.geojson);

	}

	showFeatureData = (feature) => {

		let entries = [];

		if (this.state.mapType === "points") {


			let uri = JSON.parse(feature.properties["e2e8d0ff44169432a9b6a49d415768b6aeb230e6e308eacd14c58203edec7c1e"]).value;

			//console.log(uri);

			for (let binding of this.state.results.bindings) {

				if (binding["e2e8d0ff44169432a9b6a49d415768b6aeb230e6e308eacd14c58203edec7c1e"].value == uri) {

					//console.log(binding);

					entries.push(binding);

				}

			}

		}


		if (this.state.mapType === "lines") {

			let uri = JSON.parse(feature.properties["fa632b76f18dab740f50a2c2ffe35c08fb9f336e39957c286528f3e6a596ecbc"]).value;

			//console.log(uri);

			for (let binding of this.state.results.bindings) {

				if (binding["fa632b76f18dab740f50a2c2ffe35c08fb9f336e39957c286528f3e6a596ecbc"].value == uri) {

					//console.log(binding);

					entries.push(binding);

				}

			}

		}




		let panels = []

		//console.log(this.state.head.vars);

		for (let property of this.state.head.vars) {

			let values = [];

			let items = [];

			//console.log(property, entries);

			for (let entry of entries) {

				//console.log(entry[property].value, values);

				if (entry[property] && !(values.includes(entry[property].value))) {

					//console.log(values, entry[property].value)

					let valueFormatted = this.formatElement({ rowData: entry, dataKey: property })

					values.push(entry[property].value);

					items.push(<List.Item key={entry[property].value}>{valueFormatted}</List.Item>);

				}

			}

			/* <Badge content={values.length}>{this.replaceHeader(property)}</Badge> */

			if (values.length != 0) {

				panels.push(

					<Panel key={property} header={this.replaceHeader(property)} collapsible bordered defaultExpanded style={{ "marginTop": "5px" }}>

						<List hover size={"sm"}>
							{items}
						</List>

					</Panel>

				)

			}

		}


		let feature_information = (

			<>
				<i style={{ "marginBottom": "20px", "display": "block" }}>Showing data from {entries.length} result rows.</i>

				{panels}

			</>

		)


		this.setState({ featureShown: feature_information });


	}

	renderGeoJSONHover = (feature) => {

		this.setState({ hoverLayer: false })

		let geo = JSON.parse(feature.properties.geo);

		if (this.state.mapType === "points") {

			this.geojsonHover.features = [
				{
					"type": "Feature",
					"geometry": geo
				}
			]
		}

		if (this.state.mapType === "lines") {

			this.geojsonHover.features = [
				{
					"type": "Feature",
					"geometry": geo
				},
				{
					"type": "Feature",
					"geometry": {
						"type": "Point",
						"coordinates": geo.coordinates[0]
					}
				},
				{
					"type": "Feature",
					"geometry": {
						"type": "Point",
						"coordinates": geo.coordinates[1]
					}
				},
			]

		}

		this.setState({ hoverLayer: true })

	}

	onHover = (event) => {

		if (event.features && event.features.length != 0) {

			let feature = event.features[0]

			this.renderGeoJSONHover(feature);

			this.showFeatureData(feature);

			this.setState({ cursor: "pointer" });

		} else {

			this.setState({ cursor: "default" });

		}

	}

	onHoverEnd = (event) => {

		//console.log("Hover end");

		this.setState({ cursor: "default" });

	}

	mapRef = React.createRef();

	onShowMapDisclaimer = () => {
		this.setState({ mapDisclaimer: true });
	}

	onHideMapDisclaimer = () => {
		this.setState({ mapDisclaimer: false });
	}


	render() {

		return (<>

			{this.state.warningProps && (
				<Message style={{ marginTop: "0px" }} showIcon type="info" header="Information">
					If you want to see the results in a map you neet to select a location property first.
				</Message>

			)}

			{this.state.warningResults && (
				<Message style={{ marginTop: "0px" }} showIcon type="info" header="Information">
					No results to render.
				</Message>

			)}


			<MapDisclaimerModal show={this.state.mapDisclaimer} onClose={this.onHideMapDisclaimer} />

			{this.state.loadMap && (

				<Container style={{ height: "650px" }}>

					<FlexboxGrid>
						<FlexboxGrid.Item style={{ height: "650px", overflow: "scroll" }} colspan={6}>

							<Container style={{ padding: "15px" }}>

								<Panel header={(<h5>Feature information</h5>)} style={{ padding: "5px" }} bordered>
									{this.state.featureShown}
								</Panel>
							</Container>


						</FlexboxGrid.Item>

						<FlexboxGrid.Item style={{ height: "650px" }} colspan={18}>

							<Map
								ref={this.mapRef}
								initialViewState={{
									bounds: this.state.bounds
								}}

								mapStyle="mapbox://styles/mapbox/light-v9"
								mapboxAccessToken={this.token}
								interactiveLayerIds={['data']}
								onMouseEnter={this.onHover}
								onMouseLeave={this.onHoverEnd}
								onLoad={this.renderGeoJSON}
								cursor={this.state.cursor}

							>
								{
									this.state.showFeatures && (
										<>
											<Source type="geojson" data={this.geojson}>
												{this.state.mapType == "lines" && (<Layer {...this.linesLayer} />)}
												{this.state.mapType == "points" && (<Layer {...this.pointsLayer} />)}
											</Source>

											{this.state.hoverLayer && this.state.mapType == "lines" && (
												<Source type="geojson" data={this.geojsonHover}><Layer {...this.pointsLayerHover} /><Layer {...this.linesLayerHover} /></Source>
											)}

											{this.state.hoverLayer && this.state.mapType == "points" && (
												<Source type="geojson" data={this.geojsonHover}><Layer {...this.pointsLayerHover} /></Source>
											)}


										</>
									)
								}

								<NavigationControl position="top-left" />

								<MapDisclaimer
									map={this.mapRef}
									onClick={this.onShowMapDisclaimer}
								/>

							</Map>
						</FlexboxGrid.Item>
					</FlexboxGrid>

				</Container>)}

		</>

		);


	}

}
