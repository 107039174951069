import React from "react";

import { Container, Nav } from "rsuite";

import { getPhrase } from "../../utils/Languages.js";


// Result views

import { SearchResultTable } from "./SearchResultComponents/SearchResultTable.js";
import { SearchResultMap } from "./SearchResultComponents/SearchResultMap.js";
//import { SearchResulQuery } from "./SearchResultComponents/SearchResultTable.js";


// Result postproc components

import { MapResult } from "./SearchResultComponents/MapResult.js";
import { NumericResult } from "./SearchResultComponents/NumericResult.js";
import { NumericResultKm } from "./SearchResultComponents/NumericResultKm.js";

import { DateResult } from "./SearchResultComponents/DateResult.js";
import { TextResult } from "./SearchResultComponents/TextResult.js";



export class SearchResult extends React.Component {

	constructor(props) {

		super(props);

		this.components = {

			MapResult: MapResult,
			NumericResult: NumericResult,
			NumericResultKm: NumericResultKm,
			DateResult: DateResult,
			TextResult: TextResult,
			SKOSResult: TextResult, // Need check
			HiddenResult: TextResult

		}

		this.state = {

			resultView: null,
			resultViewComponent: null,
			cleanData: []

		};

		({ data: this.data, formDefinition: this.formDefinition, query: this.query, skosValues: this.skosValues } = props);

		//console.log("[SearchResult] Data:");

		//console.log(this.data);
		//console.log(this.formDefinition);
		//console.log(this.skosValues);
		//console.log(this.query);



		for (let form of this.formDefinition) {

			if (form.object.value === this.query.rootObject) {

				this.elements = form.elements;

			}

		}

		for (let form of this.formDefinition) {

			if (form.object.value === this.query.rootObject) {

				this.elements = { ...this.elements, ...form.custom_filters };

			}

		}

		//console.log(this.elements);


	}

	componentDidMount() {

		this.postProcessResults();

		this.changeResultView("table");

	}

	postProcessResults() {

		let newData;

		for (let element of Object.keys(this.query.query.visible)) {

			//console.log(element);

			if (element in this.elements && this.elements[element].result in this.components) {

				//console.log(element, this.elements[element]);

				let result = new this.components[this.elements[element].result](this.elements[element]);

				newData = result.transformData(this.data);

			} else {

				//console.log("No component for ",element, this.elements);

			}

		}

	}

	changeResultView = (view) => {

		if (view === "table") {

			let table = (

				<SearchResultTable
					{... { data: this.data, formDefinition: this.formDefinition, query: this.query, skosValues: this.skosValues }}
				/>

			);

			this.setState({ resultView: view, resultViewComponent: table });

		}

		if (view === "map") {

			let map = (

				<SearchResultMap
					{... { data: this.data, formDefinition: this.formDefinition, query: this.query, skosValues: this.skosValues }}
				/>

			);

			this.setState({ resultView: view, resultViewComponent: map });

		}


	}

	render() {


		return (

			<Container style={{ marginTop: "25px", marginBottom: "100px" }}>

				<Nav appearance={"tabs"} activeKey={this.state.resultView} onSelect={this.changeResultView} style={{ marginBottom: 50 }}>
					<Nav.Item eventKey="table">Table</Nav.Item>
					<Nav.Item eventKey="map">Map</Nav.Item>
				</Nav>

				<Container style={{ border: "1px solid #666", marginBottom: "50px" }}>

					{this.state.resultViewComponent}

				</Container>

			</Container>

		);


	}

}
