import React from "react";


import { Container, Table, Pagination, Tooltip, Whisper, Tag, Nav } from "rsuite";

const { Column, HeaderCell, Cell } = Table;

import {
	DESCRIBE_URI,
} from "../../../config/config.js";


const TransformURI = (uri) => {

	if (uri.startsWith("http://data.europa.eu/949/")) {

		return "/describe#" + encodeURIComponent(uri);

	} else {

		return uri;

	}

}

export class SearchResultTable extends React.Component {


	constructor(props) {

		super(props);

		({ data: this.data, formDefinition: this.formDefinition, query: this.query, skosValues: this.skosValues } = props);

		let { results, head } = this.data;

		this.state = {

			pagination: {},
			tableData: [],
			tableSortColumn: null,
			tableSortType: "asc"

		}

		//console.log("Query:", this.query);

		this.state.pagination = { active: 1, total: results.bindings.length, amount: 10 };

		this.state.tableData = results.bindings.slice(
			(this.state.pagination.active - 1) * this.state.pagination.amount,
			(this.state.pagination.active) * this.state.pagination.amount
		);

		this.state.head = head;
		this.state.results = results;


		for (let form of this.formDefinition) {
			if (form.object.value === this.query.rootObject) {
				this.elements = form.elements;
			}
		}

		for (let form of this.formDefinition) {
			if (form.object.value === this.query.rootObject) {
				this.elements = { ...this.elements, ...form.custom_filters };
			}
		}


	}

	// Columns logic

	sortTable = (column, type) => {

		this.setState({ tableSortColumn: column });
		this.setState({ tableSortType: type });

		let type_op;

		if (type === "asc") {

			type_op = -1;

		} else {

			type_op = 1;

		}

		let ordered_results = this.state.results.bindings.sort(

			(a, b) => {

				//console.log(a[column].value,b[column].value);

				let va = a[column]?.value || "";

				let vb = b[column]?.value || "";

				return type_op * (va.localeCompare(vb));

			}

		);

		let slice = ordered_results.slice(
			(this.state.pagination.active - 1) * this.state.pagination.amount,
			(this.state.pagination.active) * this.state.pagination.amount
		);

		this.setState({ tableData: slice });

	}

	sortResults = (a, b) => {

		try {

			let va = parseFloat(this.elements[a].input.ref) || 0;
			let vb = parseFloat(this.elements[b].input.ref) || 0;

			// Send filters to back

			if (this.elements[a].input.ref.startsWith("F")) {
				va = 10000;
			}

			if (this.elements[b].input.ref.startsWith("F")) {
				vb = 10000;
			}

			return va - vb;

		} catch (e) {

			return 0;

		}

	}

	// Headers logic

	replaceHeader = (head) => {

		let label = "<missing label>";

		if (this.elements[head] !== undefined) {

			label = this.elements[head].input.definition.label;

		}

		return label;

	}

	// Pagination logic

	setNewPage = (p) => {


		let new_pagination = this.state.pagination;
		new_pagination.active = p;

		this.setState({ pagination: new_pagination });
		this.setState({
			tableData: this.state.results.bindings.slice(
				(this.state.pagination.active - 1) * this.state.pagination.amount,
				(this.state.pagination.active) * this.state.pagination.amount
			)
		});


		//console.log(pagination, table_data);
	};

	setNewAmount = (n) => {

		let new_pagination = this.state.pagination;
		new_pagination.amount = n;

		this.setState({ pagination: new_pagination });
		this.setState({
			tableData: this.state.results.bindings.slice(
				(this.state.pagination.active - 1) * this.state.pagination.amount,
				(this.state.pagination.active) * this.state.pagination.amount
			)
		});

		//console.log(pagination, table_data);
	}


	// Cell rendering

	EraCell = ({ rowData, dataKey, ...props }) => {

		let type, value, label, dataType;

		if (rowData[dataKey] === undefined) { // Must be handled differently

			type = "none";

			value = "N/A";

		} else {

			({ type: type, value: value, label: label, datatype: dataType } = rowData[dataKey]);

		}

		//console.log(elements[dataKey].results);

		let text = value;
		let prefix;

		let cell_content = value;

		// URIs

		if (type === "uri") {

			text = this.skosValues[text] || label || text;

			if (dataKey === "e2e8d0ff44169432a9b6a49d415768b6aeb230e6e308eacd14c58203edec7c1e") {

				if ("2050c6d81d3a75cb5768f6ce572c07da8a1406f7d4ec1ba3bae520f30f94525e" in rowData) {

					({ value: text } = rowData["2050c6d81d3a75cb5768f6ce572c07da8a1406f7d4ec1ba3bae520f30f94525e"]);

				} else {

					text = text.replace("http://data.europa.eu/949/functionalInfrastructure/operationalPoints/", "op:");

				}

				//console.log(text, value, rowData["2050c6d81d3a75cb5768f6ce572c07da8a1406f7d4ec1ba3bae520f30f94525e"]);

			}

			if (dataKey === "fa632b76f18dab740f50a2c2ffe35c08fb9f336e39957c286528f3e6a596ecbc") {

				if ("442d071369075e84260295ab6eb90397fa0b2dcee1441658b5d82b867c317f78" in rowData &&
					"ae50abaa747adda97f1fb4980dcbd6bb1b631431cf76dd487b1981bedd05eab7" in rowData) {

					let value1, value2;

					({ value: value1 } = rowData["442d071369075e84260295ab6eb90397fa0b2dcee1441658b5d82b867c317f78"]);
					({ value: value2 } = rowData["ae50abaa747adda97f1fb4980dcbd6bb1b631431cf76dd487b1981bedd05eab7"]);

					text = value1 + "-" + value2;


				} else {

					text = text.replace("http://data.europa.eu/949/functionalInfrastructure/sectionsOfLine/", "sol:");

				}

				//console.log(text, value, rowData["2050c6d81d3a75cb5768f6ce572c07da8a1406f7d4ec1ba3bae520f30f94525e"]);

			}


			cell_content = (
				<Tag color="grey" style={{ border: "dotted 1px #777" }}><a href={TransformURI(value)} target="_blank">{text}</a></Tag>
			);

		}


		// Booleans (color)

		if (type === "typed-literal" && dataType === "http://www.w3.org/2001/XMLSchema#boolean") {

			if (value === "1") {

				cell_content = (<Tag color="green">True</Tag>);

			} else {

				cell_content = (<Tag color="red">False</Tag>);

			}

		}


		// Empty formatting

		if (value === "N/A") {

			cell_content = (<Tag color="yellow"><i>no data</i></Tag>);
		}

		return (

			<Cell {...props} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>{cell_content}</Cell>

		);


	}



	render() {

		return (

			<>

				<Table
					data={this.state.tableData}
					autoHeight
					wordWrap={true}
					cellBordered={true}
					bordered={true}
					sortColumn={this.state.tableSortColumn}
					sortType={this.state.tableSortType}
					onSortColumn={this.sortTable}>
					rowHeight={54}
					affixHeader

					{
						this.state.head.vars.sort(this.sortResults).map((column) => {

							return (

								<Column key={column} align={"center"} width={200} resizable sortable>

									<HeaderCell>

										<Whisper placement="top" trigger="hover" speaker={<Tooltip>{this.replaceHeader(column)}</Tooltip>}>
											<p style={{ textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}>
												{this.replaceHeader(column)}
											</p>
										</Whisper>

									</HeaderCell>
									<this.EraCell dataKey={column} />

								</Column>

							);

						})

					}


				</Table>

				<Pagination style={{ margin: "10px" }}
					size="md"
					maxButtons={3}
					first={true}
					last={true}
					limit={this.state.pagination.amount}
					limitOptions={[10, 50, 100]}
					layout={["total", "-", "limit", "|", "pager", "skip"]}
					activePage={this.state.pagination.active}
					total={this.state.pagination.total}
					onChangePage={this.setNewPage}
					onChangeLimit={this.setNewAmount}
				/>


			</>

		);


	}

}
