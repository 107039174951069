
import DataStore from "./workers/DataStore.worker.js";

import WebworkerPromise from 'webworker-promise';

import { getPhrase, getCurrentLanguage } from "../../utils/Languages.js";

import { era, rdfs, skos, rdf, qudt, addPrefixes, removePrefixes } from "../../utils/NameSpace.js";

import { sha256_sync } from "../../utils/Misc.js"

async function loadSearchCriteriaElements(formDefinitionFileFragment, componentType, ontologyDefinition) {

	let elements = {};

	let skosValues = {};

	let search_object = removePrefixes(formDefinitionFileFragment.search_object).expanded;

	for (let def_element of formDefinitionFileFragment.search_criteria) {

		// ID of each form element is the concatenation of the property and the path to that property


		if (def_element.path === undefined || def_element.property === undefined) {

			//console.log("Element not well defined!", def_element);

			continue;

		}

		let element = { input: {}, query: {}, results: {}, type: "basic" }

		try {

			element.input.ref = def_element.ref;

			element.input.path = []


			for (let part of def_element.path.replaceAll(" ", "").split("/")) {

				if (part !== "") {

					element.input.path.push(removePrefixes(part).expanded);

				}

			}

			element.input.property = removePrefixes(def_element.property).expanded;


			let fullpath;

			if (element.input.path.length !== 0) {

				fullpath = "/" + search_object + "/" + (element.input.path.join("/") + "/" + element.input.property);

				//fullpath = "/"+(element.input.path.join("/")+"/"+element.input.property);

			} else {
				fullpath = "/" + search_object + "/" + element.input.property;
			}

			//console.log(fullpath, id);

			let id = sha256_sync(fullpath);


			// Load input definition

			element.input.definition = {}


			// DATATYPE

			if (def_element?.definition?.dataType === undefined) {

				// Get datatype and label from ontology if not defined in YAML

				element.input.definition.dataType = (await ontologyDefinition.exec("query", {
					data: { s: element.input.property, p: rdfs.range, o: null }
				}))[0].object.value;

				element.input.definition.dataType = removePrefixes(element.input.definition.dataType).expanded;

			} else {

				element.input.definition.dataType = removePrefixes(def_element.definition.dataType).expanded;

			}

			// PROPERTY TYPE  (DatatypeProperty/ObjectProperty)

			if (def_element?.definition?.propertyType === undefined) {

				let types = (await ontologyDefinition.exec("query", {
					data: { s: element.input.property, p: rdf.type, o: null }
				}));

				for (let type of types) {

					if (type.object.value === "http://www.w3.org/2002/07/owl#ObjectProperty" || type.object.value === "http://www.w3.org/2002/07/owl#DatatypeProperty") {

						element.input.definition.propertyType = type.object.value;

					}

				}


			} else {

				element.input.definition.propertyType = removePrefixes(def_element.definition.propertyType).expanded;

			}

			// UNITS

			if (def_element?.definition?.unit === undefined) {

				// Use unit acording to user selected language

				let units = (await ontologyDefinition.exec("query", {
					data: { s: element.input.property, p: era.unitOfMeasure, o: null }
				}));

				if (units.length > 0) {

					let symbols = (await ontologyDefinition.exec("query", {
						data: { s: units[0].object.value, p: qudt.symbol, o: null }
					}));

					let labels = (await ontologyDefinition.exec("query", {
						data: { s: units[0].object.value, p: rdfs.label, o: null }
					}));

					let eng_label;

					if (labels.length > 0) {

						for (let label of labels) {

							if (label.object?.language == "en") {

								eng_label = label.object.value;

							}

						}

					}

					if (symbols.length > 0) {

						let symbol = symbols[0].object.value;



						element.input.definition.unit = { "symbol": symbol, "label": eng_label }

					}

					//console.log(element.input.property, units[0].object.value, element.input.definition.unit);

				} else {

					element.input.definition.unit = undefined;

				}

			} else {

				element.input.definition.unit = def_element.definition.unit;

			}



			// LABEL

			if (def_element?.definition?.label === undefined) {

				// Use label acording to user selected language

				let labels = (await ontologyDefinition.exec("query", {
					data: { s: element.input.property, p: rdfs.label, o: null }
				}));

				for (let label of labels) {

					if (label.object?.language !== undefined && label.object.language == getCurrentLanguage()) {

						element.input.definition.label = label.object.value;

					} else {

						//console.log("Discarding label:", label);

					}

				}

				if (element.input.definition.label === undefined) {

					for (let label of labels) {

						if (label.object?.language !== undefined && label.object.language == "en") {

							element.input.definition.label = label.object.value;

						} else {

							//console.log("Discarding label:", label);

						}

					}

				}

				if (element.input.definition.label === undefined) {

					element.input.definition.label = labels[0].object.value;

				}

				if (element.input.definition.label === undefined) {

					element.input.definition.label = element.input.property;

				}

			} else {

				element.input.definition.label = def_element.definition.label;

			}

			if (element.input.definition.unit !== undefined) {

				element.input.definition.label = element.input.definition.label + " [" + element.input.definition.unit.symbol + "] ";

				//console.log(element.input.property, element.input.definition.label);

			}


			// RINFINDEX

			if (def_element?.definition?.rinfIndex === undefined) {

				let rinfIndex = (await ontologyDefinition.exec("query", {
					data: { s: element.input.property, p: era.rinfIndex, o: null }
				}));

				if (rinfIndex.length == 0) {

					//console.log("No RINF index for element:", def_element);

				}

				if (rinfIndex.length == 1) {

					element.input.definition.rinfIndex = rinfIndex[0].object.value;

				}

				if (rinfIndex.length > 1) {

					//console.log("More than one RINF index for element:", def_element, rinfIndex);

					element.input.definition.rinfIndex = rinfIndex[0].object.value;

				}

			} else {

				if (def_element.definition.rinfIndex !== null) {

					element.input.definition.rinfIndex = def_element.definition.rinfIndex;

				}
			}


			// FORM COMPONENTS

			if (def_element?.component?.form === undefined) {

				element.input.component = componentType[element.input.definition.dataType].form;

			} else {

				element.input.component = def_element.component.form;

			}

			if (def_element?.component?.query === undefined) {

				element.query = componentType[element.input.definition.dataType].query;

			} else {

				element.query = def_element.component.query;

			}

			if (def_element?.component?.result === undefined) {

				element.result = componentType[element.input.definition.dataType].result;

			} else {

				element.result = def_element.component.result;

			}

			if (element.input.component === undefined) {

				//console.log("Missing component for: ", element, componentType);

			}


			// DEFAULT VALUES

			if (def_element?.defaults !== undefined) {

				//console.log("Defaults:", def_element.defaults);

				element.input.defaults = def_element.defaults;

			}


			// SKOS VALUES

			let availableValues = [];

			if (element.input.definition.dataType === skos.Concept) {

				//Populated with SKOS values

				let conceptScheme = (await ontologyDefinition.exec("query", {
					data: { s: element.input.property, p: era.inSkosConceptScheme, o: null }
				}))[0].object.value;

				//console.log("Using ConceptScheme: ", conceptScheme);

				let concepts = (await ontologyDefinition.exec("query", {
					data: { s: null, p: skos.inScheme, o: conceptScheme }
				}));

				//console.log("Found ", concepts.length, "concepts in ", conceptScheme);

				for (let concept of concepts) {

					// In this way we identify the values that apply to RINF and not ERATV

					const rinfNote = "Value retrieved from the RINF database";

					let note = (await ontologyDefinition.exec("query", {
						data: { s: concept.subject.value, p: skos.note, o: null }
					}));

					//console.log(note);

					if (note !== undefined && note.length > 0 && note[0].object.value === "Value retrieved from the ERATV database") {

						//console.log("Skipping SKOS:", concept.subject.value, note)

						continue;

					} else {

						//console.log(note, note.length, note[0].object.value);

					}

					let labels = (await ontologyDefinition.exec("query", {
						data: { s: concept.subject.value, p: skos.prefLabel, o: null }
					}));

					// Use label acording to user selected language


					for (let label of labels) {

						if (label.object.language == getCurrentLanguage() || label.object?.language === undefined) {

							availableValues.push({ "label": label.object.value, "value": concept.subject.value });

							skosValues[concept.subject.value] = label.object.value;

						} else {

							//console.log("Discarding label:", label);

						}

					}

				}

				//console.log("Values from SKOS: ", availableValues);

				element.input.availableValues = availableValues.sort((a, b) => { return a.label.localeCompare(b.label) });

			}

			elements[id] = element;

		} catch (e) {

			//console.log("Problem processing element:", def_element);
			//console.log(e, def_element, element);

		}

	}

	return { elements: elements, skosValues: skosValues };

}

function loadCustomFilters(formDefinitionFileFragment, componentType, ontologyDefinition) {

	let custom_filters = {}

	if (formDefinitionFileFragment.custom_filters !== undefined) {

		for (let def_element of formDefinitionFileFragment.custom_filters) {

			//console.log(def_element);

			let element = { input: { ref: def_element.ref, defaults: { invisible: true } }, query: {}, results: {}, type: "custom" }

			element.input.definition = {}

			if (def_element.ref === undefined || def_element.definition === undefined || def_element.component === undefined) {

				//console.log("Element not well defined!", def_element, def_element.ref, def_element.definition, def_element.components);

				continue;

			}

			element.input.path = []

			for (let part of def_element.path.replaceAll(" ", "").split("/")) {

				if (part !== "") {

					element.input.path.push(removePrefixes(part).expanded);

				}

			}

			let id = sha256_sync(def_element.ref);

			if (def_element?.definition?.label !== undefined) {

				element.input.definition.label = def_element.definition.label;

			}


			if (def_element?.component?.form !== undefined) {

				element.input.component = def_element.component.form;

			}

			if (def_element?.component?.query !== undefined) {

				element.query = def_element.component.query;

			}

			if (def_element?.component?.result !== undefined) {

				element.result = def_element.component.result;

			}

			if (def_element?.definition?.tree !== undefined) {

				element.input.definition.tree = def_element.definition.tree;

			}


			if (def_element?.property !== undefined) {

				element.input.property = removePrefixes(def_element.property).expanded;

			}

			if (def_element?.defaults !== undefined) {

				element.input.defaults = def_element.defaults;

			}

			custom_filters[id] = element;


		}

	}

	return custom_filters

}

function loadStructureRecursive(tree, elements, filters, deep_level) {

	//console.log("Form structure:", tree, deep_level);

	let structure = { elements: [], childs: [], label: tree.label, index: tree.index, collapsed: tree.collapsed };

	if (tree.collapsed === undefined) {

		structure.collapsed = true;

	}

	//console.log(tree.collapsed);

	// Insert loaded elements by reference

	if (tree.elements !== undefined) {

		for (let element of tree.elements) {

			for (let input of Object.keys(elements)) {

				if (elements[input].input.ref === element) {

					let match = elements[input];

					match["id"] = input;

					structure.elements.push(match);

				}

			}

			// Case for custom filters

			for (let input of Object.keys(filters)) {

				if (filters[input].input.ref === element) {

					let match = filters[input];

					match["id"] = input;

					structure.elements.push(match);

					//console.log(filters[input].input.ref, filters, match);

				}

			}

		}

	}

	if (tree.childs) {

		for (let element of Object.keys(tree.childs)) {

			structure.childs.push(loadStructureRecursive(tree.childs[element], elements, filters, deep_level + 1));

		}

	}

	return structure;

}

function loadStructure(formDefinitionFileFragment, elements, filters) {

	let tree = formDefinitionFileFragment.form_structure;

	let structure = loadStructureRecursive(tree, elements, filters, 0);

	return structure;

}

export async function loadFormDefinition(formDefinitionFile, ontologyDefinition) {

	let formDefinition = []

	let skosValues = {}

	//console.group("Form definition loader");

	// Iterate over the definitions in the file, each is related to a particular object as the search root

	for (let key of Object.keys(formDefinitionFile.form)) {

		let fragment = formDefinitionFile.form[key];

		let { elements, skosValues: skosValuesPart } = await loadSearchCriteriaElements(fragment, formDefinitionFile.componentTypes, ontologyDefinition);

		skosValues = { ...skosValues, ...skosValuesPart };

		let custom_filters = loadCustomFilters(fragment, formDefinitionFile.componentTypes, ontologyDefinition);

		//console.log("Loaded custom filters:", custom_filters);

		//console.log("SKOS:", skosValues);

		let structure = loadStructure(fragment, elements, custom_filters);

		let search_object = { value: removePrefixes(fragment.search_object).expanded };

		search_object.id = sha256_sync("/" + search_object.value);

		let labels = (await ontologyDefinition.exec("query", {
			data: { s: removePrefixes(fragment.search_object).expanded, p: rdfs.label, o: null }
		}));


		// Use label acording to user selected language

		for (let label of labels) {

			if (label.object?.language !== undefined && label.object.language == getCurrentLanguage()) {

				search_object.label = label.object.value;

			}
		}

		if (search_object.label === undefined) {

			for (let label of labels) {

				if (label.object?.language !== undefined && label.object.language == "en") {

					search_object.label = label.object.value;

				}

			}

		}


		// Include root element also as regular element

		let fullpath = "/" + removePrefixes(fragment.search_object).expanded;

		let id = sha256_sync(fullpath);

		elements[id] = { id: id, input: { definition: { label: search_object.label }, property: removePrefixes(fragment.search_object).expanded } };

		formDefinition.push({ object: search_object, elements: elements, structure: structure, custom_filters: custom_filters });

	}

	console.groupEnd();

	//console.log(formDefinition);

	return { formDefinition, skosValues }

}

